import { Box } from "@mui/material";
import { DataManagementLayout } from "@pages/project-details/project-data-management/data-management-layout";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { useCoreApiClient } from "@api/use-core-api-client";
import { useAppParams } from "@router/router-helper";
import { fetchProjectDetails } from "@store/projects/projects-slice-thunk";
import { AuthenticatedRoute } from "@router/authenticated-route";
import { RequiredRoleProjectLevelName } from "@utils/access-control/project/project-access-control-types";
import {
  isProjectArchiveSelector,
  selectedProjectSelector,
} from "@store/projects/projects-selector";
import { isBetaTestingEnabledOrNotProdSelector } from "@store/ui/ui-selector";
import { ForbiddenPage } from "@pages/forbidden-page";
import { ProjectDataManagementType } from "@custom-types/project-data-management-types";
import { QueryParams } from "@router/route-params";
import { useSearchParams } from "react-router-dom";
import { isProjectDataManagementType } from "@custom-types/project-data-management-type-guard";
import { useCaptureTree } from "@hooks/use-capture-tree";
import { useMembersUtils } from "@hooks/use-member-utils";
import { UploadedData } from "@pages/project-details/project-data-management/uploaded-data/uploaded-data";
import { DataManagementTabs } from "@pages/project-details/project-data-management//data-management-tabs";
import { PreparedData } from "@pages/project-details/project-data-management/prepared-data/prepared-data";
import { DataManagementProvider } from "@context-providers/data-management/data-management-context";
import { PublishedData } from "@pages/project-details/project-data-management/published-data/published-data";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";

/** Renders project data management page */
export function ProjectDataManagement({
  projectId,
}: BaseProjectIdProps): JSX.Element {
  const [searchParams] = useSearchParams();
  const dataTypeParam = searchParams.get(QueryParams.type);
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const { companyId } = useAppParams();
  const selectedProject = useAppSelector(selectedProjectSelector);
  const isBetaTestingEnabled = useAppSelector(
    isBetaTestingEnabledOrNotProdSelector
  );
  const isProjectArchived = useAppSelector(
    isProjectArchiveSelector(selectedProject?.id ?? "")
  );
  useCaptureTree({ projectId });
  const { companyMembers, projectMembers } = useMembersUtils();

  /** Initial data management type to display */
  const initialDataType = isProjectDataManagementType(dataTypeParam)
    ? dataTypeParam
    : ProjectDataManagementType.uploadedData;

  /** Selected data management type state */
  const [selectedTab, setSelectedTab] =
    useState<ProjectDataManagementType>(initialDataType);

  // Fetches project details
  useEffect(() => {
    if (companyId && !selectedProject) {
      dispatch(fetchProjectDetails({ coreApiClient, companyId, projectId }));
    }
  }, [companyId, coreApiClient, dispatch, projectId, selectedProject]);

  if (!isBetaTestingEnabled || isProjectArchived) {
    return <ForbiddenPage />;
  }

  return (
    <AuthenticatedRoute
      key={"data-management-page"}
      requiredRoleProjectLevel={
        RequiredRoleProjectLevelName.canViewDataManagement
      }
    >
      <DataManagementProvider
        projectId={projectId}
        companyMembers={companyMembers}
        projectMembers={projectMembers}
      >
        <DataManagementLayout>
          <Box
            data-testid="project-data-management-container"
            sx={{
              width: "100%",
            }}
          >
            <DataManagementTabs
              initialDataType={initialDataType}
              setSelectedTab={setSelectedTab}
            />

            {selectedTab === ProjectDataManagementType.uploadedData && (
              <UploadedData />
            )}
            {selectedTab === ProjectDataManagementType.preparedData && (
              <PreparedData />
            )}
            {selectedTab === ProjectDataManagementType.publishedData && (
              <PublishedData />
            )}
          </Box>
        </DataManagementLayout>
      </DataManagementProvider>
    </AuthenticatedRoute>
  );
}
